import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Be8fair from "../assets/images/providers/betfair.png";

import { Col, Container, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/ProviderImg4.jpg";
import ProviderLogo5 from "../assets/images/providers/ProviderImg5.jpg";
import ProviderLogo6 from "../assets/images/providers/ProviderImg6.jpg";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import SupportIcon from "../assets/images/footer/support.png";
import SupportIcon1 from "../assets/images/footer/security.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EighteenPlus from "../assets/images/icons/home/ssc-footer-banners.png";
import gambling from "../assets/images/icons/footer/gambling.png";
import GordonMoody from "../assets/images/icons/footer/Gordon-Moody.png";
import MGA from "../assets/images/icons/footer/MGA.png";
import begambleaware from "../assets/images/icons/footer/begambleawareorg_black_png.png";
import GT from "../assets/images/icons/footer/GT-logo.png";
import GameCare from "../assets/images/icons/footer/gamecare.png";
import FunStop from "../assets/images/icons/footer/funstop.png";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="responsibleContent">
        <img src={EighteenPlus} alt="eighteen" />
        <span>Please Gamble Responsibly</span>
      </div>
      <div className="responsibleText">
        <p>
          <span>Warning:</span> Although the current score, time elapsed, video
          and other data provided on this site is sourced from "live" feeds
          provided by third parties, you should be aware that this data may be
          subject to a time delay and/or be inaccurate. Please also be aware
          that other Be8fair customers may have access to data that is faster
          and/or more accurate than the data shown on the Be8fair site. If you
          rely on this data to place bets, you do so entirely at your own risk.
          Be8fair provides this data AS IS with no warranty as to the accuracy,
          completeness or timeliness of such data and accepts no responsibility
          for any loss (direct or indirect) suffered by you as a result of your
          reliance on it.
        </p>
      </div>
      <div className="container-fluid">
        <div className="FooterLogo">
          <ul>
            <li>
              <img src={MGA} alt="gambling" />
            </li>
            <li>
              <img src={gambling} alt="gambling" />
            </li>
            <li>
              <img src={EighteenPlus} alt="eighteen" />
            </li>
            <li>
              <img src={GT} alt="gt" />
            </li>
            <li>
              <img src={GameCare} alt="gt" />
            </li>
            <li>
              <img src={FunStop} alt="gambling" />
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-links">
        <ul>
          <li>
            <a href="/contactuss">Contact Us</a>
          </li>
          <li>
            <a href="/privacypolicys ">Privacy Policy</a>
          </li>
          <li>
            <a href="/responsiblegames">Responsible Gaming</a>
          </li>
          <li>
            <a href="/fairplays">Fair Play</a>
          </li>
          <li>
            <a href="/gamesrules">Games Rules</a>
          </li>
          <li>
            <a href="/termss">Terms and Conditions</a>
          </li>
        </ul>
      </div>
      <Container className="d-none">
        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactuss">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrules">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <hr className="my-3"></hr>

        <p className="velkisentence">
          Be8fair is a product of Be8fair group which operates in accordance
          with the License granted by SVG Gambling Commission under the license
          # ---- Be8fair is Powered by Betfair.com
        </p>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>

        <hr className="my-3"></hr>

        <div className="SupportSection">
          <div className="SupportSingleSection">
            <div className="SupportImgIcon">
              <img src={SupportIcon} alt="support" />
            </div>
            <h4>
              Any question? We are <a href="/contactuss">here 24/7</a>
            </h4>
            <p>
              To give you the best player experience our support is here to give
              you the answers to your questions any time of the day of the week.
              We are a dedicated and friendly team, and most important we love
              to help.
            </p>
          </div>

          <div className="SupportSingleSection">
            <div className="SupportImgIcon">
              <img src={SupportIcon1} alt="support" />
            </div>
            <h4>A secure gaming site</h4>
            <p>
              Be8fair is fully licensed and regulated. When you play with us,
              you can be sure that we are following a high standard of
              compliance and that your transactions and details are kept safe
              and secure.
            </p>
          </div>
        </div>

        <div className="social-icons">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>
      </Container>

      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a className="active" href="/">
              <HomeIcon />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <SportsIcon />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a href="/sign-in">
              <GamesIcon />
              <span>All Casino</span>
            </a>
          </li>
          <li>
            <a href="/sign-in">
              <GamesIcon />
              <span>My Bets</span>
            </a>
          </li>
          {/* <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
          {/* <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <GamesIcon />
              <span>Ezugi</span>
            </a>
          </li> */}
          {/* <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <GamesIcon />
              <span>Evolution</span>
            </a>
          </li> */}
          <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <CashierIcon />
              <span>Deposit</span>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
