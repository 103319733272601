import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

import EzugiProvider from "../../../../assets/images/banner/Ez.png";
import EvolutionProvider from "../../../../assets/images/banner/EGaming.png";
import VivoProvider from "../../../../assets/images/banner/VivoGaming.png";
import QtechProvider from "../../../../assets/images/banner/QTech.png";
import worldcasino from "../../../../assets/images/banner/worldcasino.png";
import xpg from "../../../../assets/images/banner/xpg.png";
import supernowa from "../../../../assets/images/banner/supernowa.png";
import AESexyGaming from "../../../../assets/images/banner/AESexyGaming.jpg";

import Footer from "../../../../containers/Footer";

import UltimateRoulette from "../../../../assets/images/popular/ultimateroulette.jpg";
import CrazyTime from "../../../../assets/images/popular/Crazytimeevo.jpg";
import CashorCrash from "../../../../assets/images/popular/Cashorcrash.jpg";
import AkbarRomeoWalter from "../../../../assets/images/popular/akbaromeowalter.jpg";
import VivoAndarBahar from "../../../../assets/images/popular/Andarbaharvivo.jpg";
import VivoTeenPatti from "../../../../assets/images/popular/Teenpattivivo.jpg";
import DragonTiger from "../../../../assets/images/popular/Dragontigerezugi.jpg";

// import EzugiProvider from "../../../../assets"
const AllCasino = () => {
  return (
    <>
      <HeaderAfterLogin />{" "}
      <main className="main mt-5" style={{ height: "100vh" }}>
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container FooterPage">
          <div className="game-heading mb-4 sectionTitle">
            <h3>All Casino</h3>
          </div>
          <div>
            <div className="providerSection">
              <ul style={{ justifyContent: "flex-start" }}>
                <li>
                  <a href="/casino/ezugi">
                    <img src={EzugiProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution">
                    <img src={EvolutionProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/supernowa">
                    <img src={supernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/worldcasino">
                    <img src={worldcasino} />
                  </a>
                </li>
                <li>
                  <a href="/casino/xpg">
                    <img src={xpg} />
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo">
                    <img src={VivoProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/qtech">
                    <img src={QtechProvider} />
                  </a>
                </li>
                <li>
                  <a href="/AeSexy">
                    <img src={AESexyGaming} />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="FooterPage">
            <div className="game-heading mb-4 sectionTitle">
              <h3>Popular Games</h3>
            </div>
            <div>
              <div className="providerSection">
                <ul style={{ justifyContent: "flex-start" }}>
                  <li>
                    <a href="/casino/ezg-ultimate-roulette">
                      <img src={UltimateRoulette} />
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezg-dragon-tiger">
                      <img src={DragonTiger} />
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezgevo-crazy-time">
                      <img src={CrazyTime} />
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezgevo-cash-or-crash">
                      <img src={CashorCrash} />
                    </a>
                  </li>
                  <li>
                    <a href="/casino/sn-akbar-romeo-walter">
                      <img src={AkbarRomeoWalter} />
                    </a>
                  </li>
                  <li>
                    <a href="/casino/vivo-andar-bahar">
                      <img src={VivoAndarBahar} />
                    </a>
                  </li>
                  <li>
                    <a href="/casino/vivo-teen-patti">
                      <img src={VivoTeenPatti} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default AllCasino;
